.InputText {

  .title {
    color: var(--thirdPrimaryBtn);
    &.dark {
      color: var(--litePrimaryBg);
    }
  }
  .error {
    padding-top: 5px;

    color: var(--secondColor);
  }
  .input {
    margin-top: 10px;
    height: 40px;
    background-color: var(--invertedPrimaryBg);
    padding-left: 10px;

    &:focus::placeholder {
      color: transparent;
    }
    &:disabled {
      background-color: var(--thirdPrimaryBtn);
    }
    &.transparent {
      //padding: 0;
      margin-top: 0;

      font-size: 22px;
      color: var(--primaryTextColor);
      outline: 1px solid var(--opacityBlackColor);
      background-color: transparent;
    }
    &.maxWidth {
      width: 100%;
    }
    &.center {
      width: 100%;
      padding-left: 0;
      text-align: center;
    }
    &.placeholderUppercase::placeholder {
      text-transform: uppercase;
    }
    &.dark {
      //background-color: var(--litePrimaryBg);
      border: solid 1px var(--litePrimaryBg);
      //color: var(--primaryTextColor);
    }
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none; // Yeah, yeah everybody write about it
  }

  input[type='number'],
  input[type="number"]:hover,
  input[type="number"]:focus {
    appearance: none;
    -moz-appearance: textfield;
  }
}
