.AllBuyPopup {
    position: relative;
    background-color: var(--invertedPrimaryBg);
    color: var(--invertedPrimaryTextColor);
    padding: 20px 30px;
    min-width: 450px;
    max-width: 450px;
    overflow: hidden;

    &.load {
        pointer-events: none;
    }

    &.isOnlyCommand {
        .commandName {
            margin-top: 0;
        }
    }

    .commandName {
        margin-top: 20px;

        .title {

        }

        input {
            margin-top: 10px;
            height: 40px;
            width: calc(100%);
            padding-left: 10px;
            margin-right: 60px;
            outline: 2px solid transparent;

            &:focus {
                transition: outline .2s linear 0s;
                outline: 2px solid #6668e0;
            }

            &.error:focus {
                transition: outline .2s linear 0s;
                outline: 2px solid #e06666;
            }
        }
    }

    .command {
        min-height: 250px;
        margin-top: 20px;

        .title {

        }

        .list {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            gap: 2px;
        }

        .addBtn {
            margin-top: 20px;

            .errorText {
                width: 100%;
                margin-bottom: 10px;
                opacity: 0;
                color: var(--hoveSecondColor);
                transition: opacity 0s linear 0s;

                &.error {
                    opacity: 1;
                    transition: opacity .3s linear 0s;
                }
            }
        }

        .Member {
            min-height: 40px;
            display: grid;
            grid-template-columns: auto 1fr auto;
            align-items: center;

            .index {
                height: 100%;
                padding: 0 10px;
                background-color: #e5e5e5;
                display: flex;
                align-items: center;
            }

            .fullName {
                height: 100%;
                background-color: #e5e5e5;
                display: flex;
                align-items: center;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow-x: hidden;
            }

            .deleteBtn {
                margin: 2px 0;
                height: calc(100%);
                //width: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                //font-size: 24px;
                border: 1px solid rgba(202, 68, 59, 0.43);
                cursor: pointer;
                padding: 0 10px;
                background-color: #e5e5e5;

                &:hover {
                    background-color: var(--secondColor);
                    color: var(--primaryTextColor);
                }
            }
        }
    }

    .currentBtn {
        margin-top: 20px;

        &.disable {
            pointer-events: none;
            opacity: .5;
        }
    }

    @media screen and (max-width: 767px) {
        padding: 20px 20px;
    }
    @media screen and (max-width: 500px) {
        //padding: 15px;
        min-width: auto;
        width: calc(100vw - 60px);
    }
    @media screen and (max-width: 420px) {
        //padding: 15px;
        min-width: auto;
        width: calc(100vw - 20px);
    }
}

.AddMemberBtn {
    .topBtn {
        //position: absolute;
        //top: 0;
        //bottom: 0;
        //right: 0;
        //height: 46px;
        //width: 100%;
        //color: #464646;
        //background-color: grey;
        //transition: width 0s linear 0s;
        //display: flex;
        //justify-content: center;
        //align-items: center;
        //cursor: pointer;
        //font-size: 18px;
        //font-weight: 700;
        //
        display: flex;
        justify-content: center;
        span {
            border-bottom: solid 1px transparent;
            cursor: pointer;
            &:hover {
                font-weight: 500;
                border-bottom: solid 1px black;
            }
        }
    }

    @media screen and (max-width: 767px) {
        //height: 40px;
        .topBtn {
            height: 40px;
            font-size: 16px;
        }
    }
}

@keyframes showSearchMemberModule {
    0% {
        left: 100%;
    }
    100% {
        left: 0;
    }
}
.SelectMember {
    background-color: white;
    position: absolute;
    z-index: 1;
    left: 100%;
    top: 0;
    bottom: 0;
    width: 100%;
    padding: 20px 30px;
    display: grid;
    grid-template-rows: auto auto auto 1fr;
    animation: showSearchMemberModule both .3s ease-in-out 0s;

    .backSelectBtn {
        justify-self: start;
        display: flex;
        cursor: pointer;
        border-bottom: 1px solid transparent;
        &:hover {
            font-weight: 500;
            border-bottom: 1px solid black;
        }
    }
    .title {
        margin-top: 5px;
        font-size: 18px;
        font-weight: bold;
    }

    .searchInput {
        margin-top: 20px;
        height: 40px;
        width: 100%;
        outline: 1px solid #d2d2d2;
        padding-left: 10px;

        &:focus {
            transition: outline .3s linear 0s;
            outline: 1px solid #6668e0;
        }

    }

    .membersList {
        position: relative;
        margin-top: 10px;
        outline: 1px solid #d2d2d2;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: #f6f6f6;
        &::-webkit-scrollbar-thumb {
            -webkit-border-radius: 3px;
            background-color: rgba(166, 166, 166, 0.51);
            border-radius: 5px;
            overflow: hidden;
            border: 2px solid white;
        }
        .loadList {
            display: flex;
            justify-content: center;
            margin-top: 40px;
        }
        .emptyList {
            display: flex;
            justify-content: center;
            margin-top: 40px;
        }
        .memberItem {
            min-height: 34px;
            background-color: #eeeeee;
            border-bottom: 1px solid #eeeeee;
            display: grid;
            grid-template-columns: 1fr 80px;
            &:nth-child(odd) {
                background-color: transparent;
            }
            .fullMemberName {
                padding: 10px 0 10px 10px;
            }
            .addMemberBtn {
                height: 100%;
                border-left: 1px solid #eeeeee;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #d5d5d5;
                cursor: pointer;
                font-size: 13px;
                &:hover {
                    background-color: #dadada;
                }
            }
        }
    }
}
