.loadingList {
  min-height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
  img {
    width: 50px;
  }
  span {
    font-size: 16px;
  }
}
.EditTournament {
  padding: 20px 0 40px 15px;
  .head {
    margin-top: 16px;
    font-size: 34px;
    font-weight: bold;
  }
  .error {
    font-size: 18px;
    color: var(--hoveSecondColor);
  }
  .editCategoryBtn {
    margin-top: 10px;
    max-width: 220px;
  }
  .main {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr auto;
    .form {
      position: relative;
      z-index: 1;
      max-width: 700px;
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 20px;
      align-content: start;
      .bigDescription, .prize, .rules {
        margin-top: 5px;
        .title {
          margin-bottom: 12px;
          color: var(--thirdPrimaryBtn);
          &.dark {
            color: var(--litePrimaryBg);
          }
        }
        .editor {
          min-height: 400px;
          background-color: white;
        }
      }
      .btnBox {
        margin: 20px 0 0;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 30px;
      }
      &.disable {
        pointer-events: none;
      }
      .partnerList {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .item {
          min-height: 40px;
          padding: 10px;
          display: grid;
          grid-template-columns: 100px 1fr auto;
          column-gap: 10px;
          align-items: center;
          border: solid 1px rgb(58, 55, 72);

          .img {
            width: 100px;
            height: 100px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .itemMain {
            align-self: start;
            //width: 100%;
            //display: grid;
            //grid-template-rows: 50px auto;
            //grid-template-columns: 1fr;
          }
          //&:hover {
          //  background-color: var(--hoverBtn);
          //}
          //&:last-child {
          //  border-bottom: none;
          //}
          @media screen and (max-width: 520px){
            grid-template-columns: 100px 1fr;
            button {
              margin-top: 20px;
              grid-column: span 2;
            }
          }
        }
      }
    }
    .avatar {
      margin-top: 8px;
      width: 350px;
      height: 415px;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      justify-content: flex-end;
      padding: 23px 0 0 23px;
      min-width: 250px;
      min-height: 315px;
      .imgBox {
        overflow: hidden;
        width: calc(100%);
        height: calc(100%);
        max-width: 500px;
        max-height: 500px;
        background-color: gray;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .changeFile {
        cursor: pointer;
        .fileName {
          height: 40px;
          background-color: var(--primaryBtn);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          &:hover {
            background-color: var(--hoverBtn);
          }
        }
        .inputLogo {
          width: 0;
          height: 0;
        }
        &.disable {
          pointer-events: none;
        }
      }
      @media screen and (max-width: 1500px ){
        width: calc(50vw - 400px);
        height: calc(50vw - 335px);
      }
    }
  }
  @media screen and (max-width: 1100px){
    padding: 30px 0 40px 10px;
  }
  @media screen and (max-width: 991px){
    padding: 30px 0 40px 5px;
    .main {
      grid-template-columns: 1fr;
      .form {
        order: 2;
      }
      .avatar {
        order: 1;
        margin: 0 auto;
        width: 350px;
        height: 415px;
        padding: 10px;
      }
    }
  }
  @media screen and (max-width: 520px){
    .main {
      .avatar {
        width: calc(50vw - 400px);
        height: calc(50vw - 335px);
      }
    }
  }
}
.tox.tox-tinymce  {
  //padding: 20px;
  height: 100vh !important;
  //background-color: var(--invertedPrimaryBg);
  color: black;
  min-width: 1400px;
  width: 100%;

  @media screen and (max-width: 991px){
    min-width: calc(100vw - 100px);
  }
  @media screen and (max-width: 420px){
    min-width: calc(100vw);
    min-height: calc(100vh);
    padding-top: 50px;
  }
}
.AddPartner {
  //min-width: 300px;
  .title {
    color: var(--thirdPrimaryBtn);
    margin-bottom: 10px;
  }
  .main {
    position: relative;
    height: 40px;
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 2px;
    cursor: pointer;

    .name {
      text-overflow: ellipsis;
      background-color: var(--primaryBtn);
      height: 100%;
      padding: 0 15px;
      overflow: hidden;
      display: flex;
      align-items: center;
      color: var(--thirdPrimaryBtn);
      white-space: nowrap;
    }
    .showSelectBtn {
      background-color: var(--primaryBtn);
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: var(--grayColor);
        width: 20px;
        height: 20px;

      }
    }
    &:hover {
      .name {
        background-color: var(--hoverBtn);
        color: var(--primaryTextColor);
      }
      .showSelectBtn {
        background-color: var(--hoverBtn);
        svg {
          fill: var(--primaryTextColor);
          width: 20px;
          height: 20px;
        }
      }
    }
    &.active {
      .name {
        background-color: var(--hoverBtn);
        color: var(--primaryTextColor);
      }
      .showSelectBtn {
        background-color: var(--hoverBtn);
        svg {
          fill: var(--primaryTextColor);
          width: 20px;
          height: 20px;
          transform: rotate(180deg);
        }
      }
    }
    .list {
      min-height: 100px;
      max-height: 300px;
      z-index: 1;
      //height: 300px;
      overflow: auto;
      width: 100%;
      position: absolute;
      top: 47px;
      left: 0;
      background-color: var(--primaryBtn);
      //border: solid 1px var(--opacityBlackColor);
      .item {
        min-height: 40px;
        padding: 10px;
        display: grid;
        grid-template-columns: 100px 1fr auto;
        column-gap: 10px;
        align-items: center;
        border-bottom: solid 1px rgb(58, 55, 72);

        .img {
          width: 100px;
          height: 100px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .itemMain {
          align-self: start;
          //width: 100%;
          //display: grid;
          //grid-template-rows: 50px auto;
          //grid-template-columns: 1fr;
        }
        &:hover {
          background-color: var(--hoverBtn);
        }
        &:last-child {
          border-bottom: none;
        }

        @media screen and (max-width: 520px){
          grid-template-columns: 100px 1fr;
          button {
            margin-top: 20px;
            grid-column: span 2;
          }
        }
      }
      .loadingList {
        min-height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 50px;
        }
      }
      .emptyList {
        min-height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: var(--grayColor);
      }
    }
  }
}

