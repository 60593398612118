
html {

  --primaryBg: #110F18;
  --litePrimaryBg: #181522;

  --invertedPrimaryBg: #F1F1F1;
  --invertedPrimaryTextColor: #121212;

  --grayColor: #6d7269;
  --blackColor: #121212;
  --primaryTextColor: #F1F1F1;
  --primaryBtn: rgb(42, 41, 54);
  --primaryOpacityBtn: rgba(42, 41, 54, 0.32);
  --primaryLightOpacityBtn: rgba(42, 41, 54, 0.72);
  --thirdPrimaryBtn: rgba(241, 241, 241, 0.64);
  --hoverBtn: rgb(61, 59, 77);
  --opacityBlackColor: rgba(87, 87, 87, 0.73);

  --secondColor: #CA443B;
  --secondGreenColor: #4eca3b;
  --secondHardGreenColor: #168a00;
  --hoveSecondColor: #d31e10;
  --thirdColor: #ffae00;
  --hoverThirdColor: #d99800;


}
