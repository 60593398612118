
.AdminNavigate {

  width: 250px;
  padding: 30px 0;
  .burgerBtn {
    display: none;
  }
  .navItem {
    position: sticky;
    z-index: 1;
    min-height: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: var(--primaryBg);
    border-bottom: 1px solid var(--primaryBtn);
    padding-left: 10px;
    &:last-child {
      border-bottom: 1px solid transparent;
    }
    &:hover {
      background-color: var(--primaryLightOpacityBtn);
    }
    &.active {
      background-color: var(--hoverBtn);
    }
  }
  @keyframes showSvg {
    0% {opacity: 0}
    100% {opacity: 1}
  }
  @media screen and (max-width: 1100px){
    position: fixed;
    z-index: 100;
    left: -250px;
    top: 0;
    height: 100%;
    padding: 140px 0;
    background-color: var(--primaryBg);
    border-right: solid 1px #5b5b5b;
    transition: left .2s linear 0s ;
    .burgerBtn {
      display: block;
      position: absolute;
      right: -50px;
      top: 90px;
      background-color: var(--primaryBtn);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: right .2s linear 0.2s;
      animation: showSvg .2s linear 0.2s both;
      opacity: 0;
      border: 1px solid var(--blackColor);
      svg {
        width: 70%;
        height: 70%;
        fill: #727272;
      }
      &:hover {
        background-color: var(--hoverBtn);
        svg {
          fill: #c0c0c0;
        }
      }
    }
    .navItem {
      position: static;
    }
    &.mobileShow {
      left: -0px;
      .burgerBtn {
        right: 200px;
      }
    }
  }
  @media screen and (max-width: 767px){
    padding: 120px 0;
    .burgerBtn {
      top: 70px;
    }
  }
}

