.Footer {
  background-color: var(--litePrimaryBg);
  color: var(--primaryTextColor);
  padding: 40px 0 20px;
  overflow-x: hidden;
  .container {
    max-width: 1470px;
    padding: 0 15px;
    margin: 0 auto 0;
    .infoBox {
      width: 285px;
      font-weight: 400;
      font-size: 12px;
      color: rgba(241, 241, 241, 0.64);
      .logoBox {
        display: flex;
        flex-direction: column;
        span {
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 10px;
          line-height: 13px;
          text-transform: uppercase;
          color: #CA443B;
        }
      }
      .infoMain {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 15px;
        .year {}
        .conf {
          cursor: pointer;
          text-decoration: underline;
          a {
            color: rgba(241, 241, 241, 0.64);
            &:hover {
              color: rgba(241, 241, 241, 0.91);
            }
          }
          &:hover {
            color: rgba(241, 241, 241, 0.91);
          }
        }
        .offer {
          cursor: pointer;
          text-decoration: underline;
          a {
            color: rgba(241, 241, 241, 0.64);
            &:hover {
              color: rgba(241, 241, 241, 0.91);
            }
          }
          &:hover {
            color: rgba(241, 241, 241, 0.91);
          }
        }
        .cookie {
          a {
            color: rgba(241, 241, 241, 0.64);
            &:hover {
              color: rgba(241, 241, 241, 0.91);
            }
          }
        }
      }
    }
    .dev {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 12px;
      a {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        span {
          color: rgba(241, 241, 241, 0.64);
        }
        svg {
          width: 24px;
          height: 24px;
        }
        &:hover {
          span {
            color: rgba(241, 241, 241, 0.91);
          }
        }
      }
    }
  }
  //@media screen and (max-width: 991px){
  //  padding: 50px 0;
  //  .container {
  //    .main {
  //      row-gap: 20px;
  //      .menu {
  //        .menuList {
  //          row-gap: 10px;
  //          grid-template-columns: 1fr 1fr;
  //        }
  //      }
  //      .start {
  //        .startList {
  //          row-gap: 10px;
  //          grid-template-columns: 1fr 1fr;
  //        }
  //      }
  //    }
  //  }
  //}
  //@media screen and (max-width: 767px){
  //  padding: 30px 0;
  //  .container {
  //    grid-template-columns: 1fr;
  //    .main {
  //      row-gap: 20px;
  //      .menu {
  //        .menuList {
  //          margin-top: 10px;
  //          row-gap: 10px;
  //          grid-template-columns: 1fr 1fr 1fr;
  //        }
  //      }
  //      .start {
  //        .startList {
  //          margin-top: 10px;
  //          row-gap: 10px;
  //          grid-template-columns: 1fr 1fr 1fr;
  //        }
  //      }
  //    }
  //    .infoBox {
  //      margin-top: 40px;
  //      grid-template-columns: 1fr 1fr;
  //      width: 100%;
  //      display: grid;
  //      .logoBox {
  //        img {
  //          display: block;
  //          margin: 0 auto;
  //        }
  //      }
  //    }
  //  }
  //}
  //@media screen and (max-width: 540px){
  //  .container {
  //    grid-template-columns: 1fr;
  //    .main {
  //      row-gap: 20px;
  //      .menu {
  //        .menuList {
  //          row-gap: 10px;
  //          grid-template-columns: 1fr 1fr;
  //        }
  //      }
  //      .start {
  //        .startList {
  //          row-gap: 10px;
  //          grid-template-columns: 1fr 1fr;
  //        }
  //      }
  //    }
  //    .infoBox {
  //      margin-top: 40px;
  //      grid-template-columns: auto 1fr;
  //      width: 100%;
  //      display: grid;
  //      .logoBox {
  //        img {
  //          display: block;
  //          margin: 0 30px;
  //        }
  //      }
  //    }
  //  }
  //}
  //@media screen and (max-width: 420px){
  //  .container {
  //    grid-template-columns: 1fr;
  //    .main {
  //      row-gap: 20px;
  //      padding-left: 5vw;
  //      .menu {
  //        .menuList {
  //          row-gap: 10px;
  //          grid-template-columns: 1fr;
  //        }
  //      }
  //      .start {
  //        .startList {
  //          row-gap: 10px;
  //          grid-template-columns: 1fr;
  //        }
  //      }
  //    }
  //    .infoBox {
  //      margin-top: 40px;
  //      grid-template-columns: auto 1fr;
  //      width: 100%;
  //      display: grid;
  //      .logoBox {
  //        img {
  //          display: block;
  //          margin: 0 10px;
  //          width: 100%;
  //        }
  //      }
  //    }
  //  }
  //}
}
