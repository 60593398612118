.TaskItem {
  position: relative;
  margin-top: 20px;
  background: var(--primaryOpacityBtn);
  border: 1px solid #F5F5F5;
  border-radius: 8px;
  padding: 25px 35px;
  .title {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    gap: 20px;
    //overflow-x: hidden;
    //max-width: calc(100vw - 140px);
    .name {
      //white-space: nowrap;
      //text-overflow: ellipsis;
      overflow: hidden;
      font-weight: 700;
      font-size: 28px;
    }
    .status {
      margin-top: 5px;
      font-size: 18px;
      background-color: var(--primaryBtn);
      padding: 5px 15px;
      color: gray;
      &.await {
        color: gray;
      }
      &.change {
        color: #adad01;
      }
      &.reject {
        color: #b70000;
      }
      &.access {
        color: #00be00;
      }
      .closeAddResult {
        color: gray;
      }
    }
  }
  .isOpenBtn {
    margin-top: 10px;
    span {
      display: inline-block;
      margin-right: 10px;
      font-size: 18px;
      cursor: pointer;
      //text-transform: uppercase;
      //text-decoration: underline;
      border-bottom: solid 1px var(--grayColor);
      color: var(--grayColor);
      &:hover {
        color: var(--primaryTextColor);
        border-bottom: solid 1px var(--primaryTextColor);
      }
    }
    b {
      margin-left: 10px;
    }
  }
  .description {
    margin-top: 20px;
    white-space: pre-wrap;
    line-height: 1.4;
  }
  .bigDescription {
    margin-top: 20px;
  }
  .moreBtn {
    margin-top: 10px;
  }
  .main {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 20px;
    .resultBox {
      max-width: 500px;
      .link {}
      .result {
        margin-top: 20px;
        .time {
          display: flex;
          column-gap: 20px;
          input {
            width: 100px;
          }
        }
        .amount {
          input {
            width: 152px;
          }
        }
        .weight {
          display: flex;
          column-gap: 20px;
          input {
            width: 100px;
          }
        }
      }
      &.disable {
        pointer-events: none;
        input {
          background-color: #adadad;
        }
      }
    }
    .btnBox {
      align-self: end;
      min-width: 250px;
      max-width: 400px;
    }
  }

  &.await {
    border: 1px solid gray;
    .title {
      .status {
        color: gray;
      }
    }
  }
  &.access {
    border: 1px solid green;
    .title {
      .status {
        color: green;
      }
    }
  }
  &.reject {
    border: 1px solid red;
    .title {
      .status {
        color: red;
      }
    }
  }

  @media screen and (max-width: 767px){
    padding: 15px 25px;
    .main {
      grid-template-columns: 1fr;
      gap: 20px;
      .resultBox {
        //max-width: 400px;
      }
      .btnBox {
        width: 100%;
        max-width: 500px;
      }
    }
  }

  @keyframes visible {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  &.big {
    //position: fixed;
    //z-index: 10;
    //top: 0;
    //bottom: 0;
    //left: 0;
    //right: 0;
    //background-color: var(--primaryBtn);
    animation: visible .2s linear .1s both;
    padding: 0;
    background-color: var(--primaryBg);
    border: none;
    min-height: calc(100vh - 210px);
    display: grid;
    grid-template-rows: auto auto 1fr auto;
    //max-height: 100vh;
  }
}
