.PrivacyPolicy {
    padding: 20px 15px 0;
    max-width: 1470px;
    margin: 0 auto;
    h1 {
        font-size: 24px;
        margin: 20px auto;
    }
    p {
        margin-top: 10px;
        line-height: 1.4;
        text-indent: 20px;
    }
    ol {
        padding-left: 20px;
        line-height: 1.4;
    }
    a {
        color: #0048ff;
    }
}
