//@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

pre {
  max-width: 100vw;
  white-space: pre-wrap;
  overflow-x: auto;
  ul {
    padding-inline-start: 40px;
    display: flex;
    flex-direction: column;
    gap: 0.19em;
  }
  ol {
    padding-inline-start: 40px;
    display: flex;
    flex-direction: column;
    gap: 0.19em;
  }
  img {
    max-width:  calc(100vw - 40px);
    height: auto;
  }
  iframe {
    max-width:  calc(100vw - 40px);
    max-height: calc(100vw / 1.65 - 40px) !important;
  }

}

.tox-notifications-container {
  display: none !important;
}

.tox.tox-tinymce.tox-fullscreen {
  max-width: 100vw !important;
  top: 80px !important;;
  bottom: 0 !important;
  height: calc(100vh - 80px) !important;
  @media screen and (max-width: 767px){
    top: 60px !important;;
    height: calc(100vh - 60px) !important;
  }
  @media screen and (max-width: 1100px){
    padding-top: 50px;
    background-color: white !important;
  }
}
.tox.tox-tinymce {
  color: black;
  border-radius: 0;
  min-height: 400px;;
  background-color: white;
  .tox-statusbar__text-container {
    display: none;
  }
}
.react-datepicker {
  font-size: 1.1em !important;
  //width: 100%;
  border-radius: 0;
  padding: 0 2px;
}
.react-datepicker__month-container {
  width: 100%;
}
.react-datepicker__day, .react-datepicker__day-name {
  margin: 5px;
  padding: 2px;
  width: 30px;
}
.react-datepicker__current-month {
  padding: 6px 0;
}
.react-datepicker__navigation--next, .react-datepicker__navigation--previous {
  margin-top: 7px;
}



* {
  padding: 0;
  margin: 0;
  border: 0;
}

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus, :active {
  outline: none;
}

a:focus, a:active {
  outline: none;
}

nav, header, main, footer, aside {
  display: block;
}

html, body {
  background-color: var(--litePrimaryBg);
  color: var(--primaryTextColor);
  line-height: 1.2;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input, button, textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a, a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

//ul li {
//  list-style: none;
//}

img {
  vertical-align: top;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: 400;
}
