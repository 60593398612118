.AddResultItemPage {
  min-height: calc(100vh - 80px);
  margin-bottom: 20px;
  .container {
    max-width: 1470px;
    margin: 0 auto;
    padding: 0 15px;

    .main {
      margin-top: 20px;
      //padding: 40px 0;
    }
  }
}
