.StatisticPage {
    padding: 20px 0 40px 25px;
    overflow: hidden;
    .head {
        font-size: 34px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .selectBox {
        margin-top: 10px;
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
    }
    .dateBox {
        position: relative;
        margin-top: 20px;
        .title {
            color: var(--grayColor);
            margin-bottom: 10px;
        }
        .currentTournament {
            position: relative;
            height: 40px;
            display: grid;
            grid-template-columns: 300px auto;
            column-gap: 2px;
            cursor: pointer;

            .name {
                width: 100%;
                text-overflow: ellipsis;
                background-color: var(--primaryBtn);
                height: 100%;
                padding: 0 5px;
                min-height: 40px;
                overflow: hidden;
                display: grid;
                grid-template-columns: 1fr auto;
                align-items: center;
                color: var(--thirdPrimaryBtn);
                white-space: nowrap;
                text-align: center;
                justify-content: center;
                .clearDate {
                    width: 30px;
                    height: 30px;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 3px;
                    &:hover {
                        background-color: rgba(171, 2, 2, 0.62);
                    }
                }
            }
            .showSelectBtn {
                background-color: var(--primaryBtn);
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    fill: var(--grayColor);
                    width: 20px;
                    height: 20px;

                }
            }
            &.active {
                .name {
                    background-color: var(--hoverBtn);
                    color: var(--primaryTextColor);
                }
                .showSelectBtn {
                    background-color: var(--hoverBtn);
                    svg {
                        fill: var(--primaryTextColor);
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
        .datePickerBox {
            justify-self: center;
            flex-direction: column;
            max-width: 340px;
            padding-top: 10px;
            display: flex;
            justify-content: center;
        }
        @media screen and (max-width: 767px){
            max-width: 340px;
            .currentTournament {
                grid-template-columns: 1fr auto;

            }
        }
    }
    .allPrice {
        margin-top: 20px;
        span {
            margin: 0 6px;
        }
    }
    .header {
        display: grid;
        grid-template-columns: 200px 200px 200px 200px 120px 100px;
        column-gap: 20px;
        background-color: var(--primaryBtn);
        min-height: 50px;
        .field {
            min-width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
        }
    }
    .list {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        //gap: 10px;
        flex-wrap: wrap;
        overflow-x: auto;
        border: solid 1px var(--primaryBtn);
        padding-bottom: 10px;

        //-webkit-user-select: none;
        //-khtml-user-select: none;
        //-moz-user-select: none;
        //-ms-user-select: none;
        //user-select: none;
        .loadingList {
            width: 100%;
            min-height: 450px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img {
                width: 50px;
            }
        }
        .emptyList {
            width: 100%;
            min-height: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: var(--grayColor);
        }
        &.drag {
            cursor: move;
            div {
                pointer-events: none;
                cursor: move;
            }
        }
    }
    .loadingList {
        min-height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 20px;

        img {
            width: 40px;
        }
    }
    @media screen and (max-width: 1100px){
        padding: 20px 0 40px 10px;
    }
    @media screen and (max-width: 767px){
        padding: 20px 0 40px 5px;
    }
}
.StatisticItem {
    border-bottom: solid 1px var(--hoverBtn);
    padding: 10px 0;
    &:last-child {
        border-bottom: none;
    }
    .main {
        display: grid;
        grid-template-columns: 200px 200px 200px 200px 120px 100px;
        column-gap: 20px;
        min-height: 20px;
        .field {
            min-width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            text-align: center;
            word-wrap: anywhere;
        }
    }
}
.SelectTournament {
    width: 100%;
    max-width: 340px;
    position: relative;
    z-index: 1;
    //min-width: 300px;
    .title {
        color: var(--grayColor);
        margin-bottom: 10px;
    }
    .currentTournament {
        position: relative;
        height: 40px;
        display: grid;
        grid-template-columns: 300px auto;
        column-gap: 2px;
        cursor: pointer;

        .name {
            text-overflow: ellipsis;
            background-color: var(--primaryBtn);
            height: 100%;
            padding: 0 15px;
            overflow: hidden;
            display: flex;
            align-items: center;
            color: var(--thirdPrimaryBtn);
            white-space: nowrap;
        }
        .showSelectBtn {
            background-color: var(--primaryBtn);
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                fill: var(--grayColor);
                width: 20px;
                height: 20px;

            }
        }
        &:hover {
            .name {
                background-color: var(--hoverBtn);
                color: var(--primaryTextColor);
            }
            .showSelectBtn {
                background-color: var(--hoverBtn);
                svg {
                    fill: var(--primaryTextColor);
                    width: 20px;
                    height: 20px;
                }
            }
        }
        &.active {
            .name {
                background-color: var(--hoverBtn);
                color: var(--primaryTextColor);
            }
            .showSelectBtn {
                background-color: var(--hoverBtn);
                svg {
                    fill: var(--primaryTextColor);
                    width: 20px;
                    height: 20px;
                    transform: rotate(180deg);
                }
            }
        }
        .tournamentList {
            max-height: 300px;
            //height: 300px;
            overflow: auto;
            width: 100%;
            position: absolute;
            top: 47px;
            left: 0;
            background-color: var(--primaryBtn);
            border: solid 1px var(--opacityBlackColor);
            .item {
                min-height: 40px;
                padding: 10px;
                display: flex;
                align-items: center;
                border-bottom: solid 1px rgb(58, 55, 72);
                &:hover {
                    background-color: var(--hoverBtn);
                }
                &:last-child {
                    border-bottom: none;
                }
            }
            .loadingList {
                min-height: 150px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                img {
                    width: 50px;
                }
            }
        }
    }
    @media screen and (max-width: 767px){
        .currentTournament {
            grid-template-columns: 1fr auto;

        }
    }
}
