.LoginPopup {
  background-color: rgba(17, 15, 24, 0.83);
  position: fixed;
  z-index: 20;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  max-height: 100vh;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover:not(.main) {
    .closeBtn {
      &:before, &:after {
        opacity: 1;
      }
    }
  }

  .main {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      .closeBtn {
        &:before, &:after {
          opacity: .6;
        }
      }
    }

    .closeBtn {
      position: absolute;
      width: 40px;
      height: 40px;
      top: -25px;
      right: -35px;
      cursor: pointer;

      &:before, &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: white;
        opacity: .6;
        transform: rotate(45deg) translateX(1px);
      }

      &:after {
        transform: rotate(-45deg) translateX(1px);
      }

      &:hover {
        &:before, &:after {
          opacity: 1;
        }
      }

      @media screen and (max-width: 600px){
        top: 25px;
        right: 10px;
        width: 30px;
        height: 30px;
        &:before, &:after {
          background-color: black;
        }
      }
    }
  }
}

.Login, .Registration {
  min-width: 500px;
  min-height: 100px;
  background-color: var(--invertedPrimaryBg);
  border-radius: 10px;
  padding: 30px;

  .title {
    font-weight: 700;
    font-size: 32px;
    text-transform: uppercase;
    color: var(--blackColor);
  }
  .errorMessage {
    margin-top: 10px;
    color: var(--hoveSecondColor);
    width: 100%;
    word-wrap: anywhere;
    white-space: pre-wrap;
    max-width: 400px;
    font-size: 18px;
  }
  .success {
    max-width: 400px;

    .successMessageTitle {
      font-weight: 700;
      font-size: 32px;
      text-transform: uppercase;
      color: var(--blackColor);
    }
    .successMessage {
      margin-top: 10px;

      font-weight: 500;
      font-size: 22px;
      color: var(--secondGreenColor);
      word-wrap: anywhere;
      white-space: pre-wrap;
    }
  }
  form {
    width: 100%;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 10px;
    overflow: hidden;
    input {
      height: 50px;
      border: 1px solid rgba(18, 18, 18, 0.12);
      padding-left: 10px;
      font-size: 20px;

      &::placeholder {
        text-transform: uppercase;
      }
    }
    .sendBtnBox {
      margin-top: 10px;
      min-height: 40px;
    }

    //button {
    //  margin-top: 20px;
    //  height: 50px;
    //  background-color: var(--secondColor);
    //  color: var(--primaryTextColor);
    //  font-weight: 600;
    //  font-size: 24px;
    //}
  }

  .changeMode {
    margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
    color: rgba(18, 18, 18, 0.64);
    display: flex;
    gap: 10px;

    .btn {
      font-weight: 500;
      font-size: 18px;
      text-decoration-line: underline;
      color: rgba(18, 18, 18, 0.64);
      cursor: pointer;
    }
  }

  @media screen and (max-width: 520px) {
    padding: 25px 15px;
    min-width: auto;
    width: calc(100vw - 40px);
    margin: 0 auto;
    .title {
      font-size: 26px;
    }
    form {
      input {
        height: 50px;
        border: 1px solid rgba(18, 18, 18, 0.12);
        padding-left: 10px;
        font-size: 16px;
      }

      button {
        font-size: 18px;
      }
    }
  }
}
