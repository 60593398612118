.TournamentDescription {
  margin-top: 20px;
  padding: 0 15px;
  overflow-x: hidden;
  width: 100%;
  //max-width: calc(100vw - 40px);
  .title {
    font-weight: 700;
    font-size: 34px;
  }
  .text {
    margin-top: 10px;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.2;
    //color: var(--primaryTextColor);
    overflow-x: hidden;
  }
  .emptyList {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--grayColor);
  }
  @media screen and (max-width: 540px) {
    .title {
      font-size: 24px;
    }
  }
}
