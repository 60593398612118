.ResultPage {
  padding: 20px 0 40px 25px;
  .head {
    font-size: 34px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .selectBox {
    margin-top: 10px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  .list {
    margin-top: 20px;
    .loadingList {
      min-height: 450px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 20px;
      img {
        width: 50px;
      }
      span {
        font-size: 16px;
      }
    }
    .emptyList {
      min-height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: var(--grayColor);
    }
    .btnBox {
      margin-top: 20px;
      margin-bottom: 40px;
      padding: 0 15px;
    }
  }
  @media screen and (max-width: 1100px){
    padding: 20px 0 40px 10px;
  }
  @media screen and (max-width: 767px){
    padding: 20px 0 40px 5px;
    .head {
      font-size: 28px;
    }
  }
}
.SelectTournament {
  width: 100%;
  max-width: 342px;
  //min-width: 300px;
  .title {
    color: var(--grayColor);
    margin-bottom: 10px;
  }
  .currentTournament {
    position: relative;
    height: 40px;
    display: grid;
    grid-template-columns: 300px auto;
    column-gap: 2px;
    cursor: pointer;

    .name {
      text-overflow: ellipsis;
      background-color: var(--primaryBtn);
      height: 100%;
      padding: 0 15px;
      overflow: hidden;
      display: flex;
      align-items: center;
      color: var(--thirdPrimaryBtn);
      white-space: nowrap;
    }
    .showSelectBtn {
      background-color: var(--primaryBtn);
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: var(--grayColor);
        width: 20px;
        height: 20px;

      }
    }
    &:hover {
      .name {
        background-color: var(--hoverBtn);
        color: var(--primaryTextColor);
      }
      .showSelectBtn {
        background-color: var(--hoverBtn);
        svg {
          fill: var(--primaryTextColor);
          width: 20px;
          height: 20px;
        }
      }
    }
    &.active {
      .name {
        background-color: var(--hoverBtn);
        color: var(--primaryTextColor);
      }
      .showSelectBtn {
        background-color: var(--hoverBtn);
        svg {
          fill: var(--primaryTextColor);
          width: 20px;
          height: 20px;
          transform: rotate(180deg);
        }
      }
    }
    .tournamentList {
      max-height: 300px;
      //height: 300px;
      overflow: auto;
      width: 100%;
      position: absolute;
      top: 47px;
      left: 0;
      background-color: var(--primaryBtn);
      border: solid 1px var(--opacityBlackColor);
      .item {
        min-height: 40px;
        padding: 10px;
        display: flex;
        align-items: center;
        border-bottom: solid 1px rgb(58, 55, 72);
        &:hover {
          background-color: var(--hoverBtn);
        }
        &:last-child {
          border-bottom: none;
        }
      }
      .loadingList {
        min-height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 50px;
        }
      }
    }
  }
  @media screen and (max-width: 767px){
    .currentTournament {
      grid-template-columns: 1fr auto;

    }
  }
}
