/* Скрытие - Скрол бара (ScrollBar) */
::-webkit-scrollbar-button {
  //background-image:url('');
  background-repeat:no-repeat;
  width: 0px;
  height: 0px;
  pointer-events: none;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 3px;
  background-color: rgba(166, 166, 166, 0.51);
  border-radius: 5px;
  overflow: hidden;
  border: 2px solid var(--litePrimaryBg);
}

::-webkit-scrollbar-thumb:hover{
  background-color: rgba(232, 232, 232, 0.51);
}

::-webkit-resizer{
  //background-image:url('');
  background-repeat:no-repeat;
  width:4px;
  height:0px;
}

::-webkit-scrollbar{
  width: 10px;
  height: 10px;
}
/* END Скрытие - Стилизация Скрол бара (ScrollBar) */
