.PrizePage {
  min-height: calc(100vh - 80px);
  .container {
    max-width: 1470px;
    margin: 0 auto;
    padding: 0 15px;
    .main {
      padding: 20px 0;
      .titlePage {
        margin-top: 10px;
        font-weight: 700;
        font-size: 34px;
        padding: 10px 0;
      }
      .emptyList {
        min-height: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: var(--grayColor);
      }
      .text {
        margin-top: 10px;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.2;
        //color: var(--primaryTextColor);
        overflow-x: hidden;
      }
    }
  }
  @media screen and (max-width: 991px){
    .container {
      .main {
        padding: 20px 0;
      }
    }
  }
  @media screen and (max-width: 540px){
    .container {
      .main {
        .titlePage {
          font-size: 24px;
        }
      }
    }
  }
}
