.TournamentTable {

    margin: 20px auto 0;
    overflow: hidden;
    max-width: calc(100vw - 10px);
    .tableTitle {
        padding-left: 15px;
        font-size: 34px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .finishStatus {
        margin-left: 15px;
        margin-top: 10px;
        display: flex;
        span {
            background-color: var(--secondColor);
            padding: 5px 15px;
            border-radius: 5px;
        }
    }

    .categoryFilter {
        padding-left: 15px;
        position: relative;
        z-index: 1;
        margin-top: 10px;
        max-width: 300px;

        .activeSelect {
            height: 40px;
            display: grid;
            grid-template-columns: 1fr auto;
            column-gap: 2px;
            cursor: pointer;

            span {
                background-color: var(--primaryBtn);
                height: 100%;
                padding: 0 15px;
                overflow: hidden;
                display: flex;
                align-items: center;
                color: var(--thirdPrimaryBtn);
            }

            .showSelectBtn {
                background-color: var(--primaryBtn);
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    fill: var(--grayColor);
                    width: 20px;
                    height: 20px;

                }
            }

            &:hover {
                span {
                    background-color: var(--hoverBtn);
                    color: var(--primaryTextColor);
                }

                .showSelectBtn {
                    background-color: var(--hoverBtn);

                    svg {
                        fill: var(--primaryTextColor);
                        width: 20px;
                        height: 20px;
                    }
                }
            }

            &.active {
                span {
                    background-color: var(--hoverBtn);
                    color: var(--primaryTextColor);
                }

                .showSelectBtn {
                    background-color: var(--hoverBtn);

                    svg {
                        fill: var(--primaryTextColor);
                        width: 20px;
                        height: 20px;
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .selectCategoryBox {
            margin-left: 15px;
            max-height: 152px;
            overflow: auto;
            width: calc(100% - 15px);
            position: absolute;
            top: 47px;
            left: 0;
            background-color: var(--primaryBtn);

            .categoryItem {
                min-height: 40px;
                display: flex;
                align-items: center;
                padding: 15px 0 15px 15px;
                text-overflow: ellipsis;
                overflow: hidden;
                line-height: 1.4;
                border-bottom: 1px solid var(--opacityBlackColor);
                cursor: pointer;

                &:hover {
                    background-color: var(--hoverBtn);
                }

                &:last-child {
                    border-bottom: 1px solid transparent;
                }
            }
        }
    }

    .table {
        padding-left: 15px;
        margin-top: 20px;
        overflow-x: auto;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        padding-bottom: 10px;

        display: flex;
        flex-direction: column;
        justify-content: stretch;

        .emptyList {
            min-height: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: var(--grayColor);
        }
        &.drag {
            cursor: move;
            div {
                pointer-events: none;
                cursor: move;
            }
        }
        .tableList {
            border-left: solid 1px var(--litePrimaryBg);
        }
    }

    .loadingList {
        min-height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 20px;

        img {
            width: 40px;
        }
    }

    @media screen and (max-width: 540px){
        .tableTitle {
            font-size: 24px;
        }
    }
}

.TableHead {
    width: 100%;
    min-height: 60px;
    display: flex;
    background-color: var(--litePrimaryBg);

    div {
        position: relative;
        background-color: var(--litePrimaryBg);
        flex: 0 0 240px;
        padding: 10px 20px 10px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .name {
        flex: 1 1 250px;
        min-width: 250px;
        max-width: 250px;
        font-weight: bold;
        display: flex;
        justify-content: flex-start;
        padding-left: 40px;
        @media screen and (max-width: 410px) {
            min-width: calc(100vw - 160px);
        }
    }

    .top {
        flex: 0 0 130px;
        font-weight: bold;
        display: grid;
        grid-template-columns: auto auto;

        .sortBox {
            align-self: center;
            width: 30px;
            display: flex;
            flex-direction: column;
            row-gap: 6px;

            .topSort {
                height: 0px;
                width: 0px;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                opacity: .2;
                border-bottom: 10px solid var(--primaryTextColor);
            }

            .bottomSort {
                height: 0px;
                width: 0px;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                opacity: .2;
                border-top: 10px solid var(--primaryTextColor);
            }

            &.sort {
                .topSort.active, .bottomSort.active {
                    opacity: 1;
                }
            }
        }
        .topText {
            margin: 0 0 0 5px;
        }
        //&:before {
        //    content: '';
        //    align-self: center;
        //    position: relative;
        //    left: -10px;
        //    z-index: 1;
        //    width: 0;
        //    height: 0;
        //    border-left: 10px solid transparent;
        //    border-right: 10px solid transparent;
        //    opacity: .2;
        //    border-top: 10px solid var(--primaryTextColor);
        //}
        //
        //&.sort:before {
        //    opacity: .8;
        //}
    }

    .task {
        display: grid;
        align-items: start;
        grid-template-columns: 30px auto;
        grid-template-rows: 1fr;
        column-gap: 15px;

        .sortBox {
            align-self: center;
            max-width: 30px;
            display: flex;
            flex-direction: column;
            row-gap: 6px;

            .topSort {
                height: 0px;
                width: 0px;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                opacity: .2;
                border-bottom: 10px solid var(--primaryTextColor);
            }

            .bottomSort {
                height: 0px;
                width: 0px;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                opacity: .2;
                border-top: 10px solid var(--primaryTextColor);
            }

            &.sort {
                .topSort.active, .bottomSort.active {
                    opacity: 1;
                }
            }
        }

        .taskName {
            margin-top: 5px;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            padding: 0;

            span:first-child {
                flex-grow: 0;
                font-weight: bold;
                white-space: pre-wrap;
                word-wrap: anywhere;
                overflow: hidden;
                width: 100%;
                text-overflow: ellipsis;
                cursor: pointer;
            }

            span:last-child {
                margin-top: 5px;
                color: var(--grayColor);
                cursor: pointer;

                &:hover {
                    color: var(--primaryTextColor);
                    text-decoration: underline;
                }
            }
        }

        .infoBtn {
            padding: 0 0 0 5px;
            font-size: 30px;
            cursor: pointer;
            color: var(--grayColor);

            &:hover {
                color: var(--primaryTextColor);
            }
        }
    }
}

.FullTask {
    background-color: var(--invertedPrimaryBg);
    color: var(--invertedPrimaryTextColor);
    padding: 20px;
    //min-width: 600px;
    //max-width: 800px;
    width: 600px;
    //height: 340px;
    @media screen and (max-width: 660px) {
        width: calc(100vw - 60px);
        //height: calc(100vw / 1.65 - 60px);
    }
    @media screen and (max-width: 420px) {
        width: calc(100vw - 20px);
        //height: calc(100vw / 1.65 - 20px);
    }

    .showBigTaskBtn {
        display: flex;
        justify-content: center;
        span {
            margin: 10px auto 0;
            display: inline-block;
            font-size: 18px;
            cursor: pointer;
            //text-transform: uppercase;
            //text-decoration: underline;
            border-bottom: solid 1px var(--primaryBtn);
            color: var(--primaryBtn);
            &:hover {
                color: var(--litePrimaryBg);
                border-bottom: solid 1px var(--litePrimaryBg);
            }
        }

    }
    .fullTaskName {
        font-weight: bold;
        font-size: 22px;
    }

    .fullTaskDescription {
        margin-top: 10px;
        white-space: pre-wrap;
        line-height: 1.4;
    }

    .closeBtn {
        margin-top: 20px;
    }
}

.TableResultItem {
    position: relative;
    height: 100%;
    display: flex;

    .name {
        flex: 1 1 310px;
        display: grid;
        grid-template-columns: auto 1fr;
        min-width: 310px;
        max-width: 310px;

        //height: 40px;
        text-align: center;
        //display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px 10px 20px;
        border-bottom: solid 1px var(--primaryLightOpacityBtn);
        span {
            position: relative;
            top: 2px;
            max-width: 300px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: start;
            span {
                display: inline-block;
                border-bottom: 1px solid transparent;
            }
        }

        .place {
            justify-self: flex-start;
            display: inline-block;
            //min-width: 50px;
            text-align: start;
            margin-right: 15px;
        }

        &:hover {
            cursor: pointer;

            span {
                span {
                    display: inline-block;
                    border-bottom: 1px solid var(--thirdPrimaryBtn);
                }
            }

            .place {
                border-bottom: none;
            }
        }

        @media screen and (max-width: 410px) {
            min-width: calc(100vw - 100px);
        }
    }

    .teamName {
        flex: 1 1 310px;
        display: grid;
        grid-template-columns: auto 1fr;
        min-width: 310px;
        max-width: 310px;

        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 10px 20px 10px 20px;
        border-bottom: solid 1px var(--primaryLightOpacityBtn);
        span {
            max-width: 300px;
            text-align: start;
            .commandName {
                font-size: 18px;
                cursor: pointer;
                &:hover {
                    border-bottom: 1px solid var(--primaryTextColor);
                }
            }
            .members {
                display: inline-block;
                margin-top: 5px;
                color: var(--grayColor);
            }
        }

        .place {
            justify-self: flex-start;
            display: inline-block;
            //min-width: 50px;
            text-align: start;
            margin-right: 15px;
        }


        @media screen and (max-width: 410px) {
            min-width: calc(100vw - 100px);
        }
    }

    .top {
        border-bottom: solid 1px var(--primaryLightOpacityBtn);
        flex: 0 0 70px;
        padding: 10px 20px 10px 10px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 72px;
        &:last-child {
            border-bottom: none;
        }
    }

    .result {
        border-bottom: solid 1px var(--primaryLightOpacityBtn);
        flex: 0 0 240px;
        padding: 10px 0 10px 40px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        div {
            height: 100%;
            display: flex;
            align-items: center;
        }
        .resultInfo {
            //background-color: red;
            display: flex;
            justify-content: center;
            gap: 10px;
            padding: 5px 0 0;

            .status {
                padding: 5px;
                flex: 0 0 auto;
                cursor: pointer;

                svg {
                    fill: var(--grayColor);
                }

                &.access {
                    svg {
                        fill: var(--secondGreenColor);
                    }
                }

                &.await {
                    svg {
                        fill: var(--grayColor);
                    }
                }
                &.change {
                    svg {
                        fill: var(--thirdColor);
                    }
                }

                &.reject {
                    svg {
                        fill: var(--secondColor);
                    }
                }
            }

            .videoBtn {
                padding: 5px;
                flex: 0 0 auto;
                cursor: pointer;

                svg path {
                    fill: var(--grayColor);
                }

                &:hover {
                    svg path {
                        fill: var(--invertedPrimaryBg);
                    }
                }
            }
        }
    }
    &:after {
        content: '';
        display: block;
        position: absolute;
        pointer-events: none;
        bottom: 0px;
        height: 1px;
        width: 100%;
        border-bottom: solid 1px var(--primaryLightOpacityBtn);
    }
}

.VideoBlock {
    width: 600px;
    height: 340px;
    background-color: black;
    @media screen and (max-width: 660px) {
        width: calc(100vw - 60px);
        height: calc(100vw / 1.65 - 60px);
    }
    @media screen and (max-width: 420px) {
        width: calc(100vw - 20px);
        height: calc(100vw / 1.65 - 20px);
    }
}

.profilePopup {
    padding: 20px;
    background-color: var(--invertedPrimaryBg);
    color: var(--invertedPrimaryTextColor);
    width: 400px;
    border-radius: 5px;

    .avatar {
        margin: 20px auto 0;
        width: 300px;
        height: 300px;
        border-radius: 50%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .name {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 22px;
        text-align: center;
        white-space: pre-line;
        font-weight: bold;
    }

    .sex, .city, .gym, .size, .weight, .height {
        margin-top: 5px;
    }

    .closeBtnBox {
        margin-top: 20px;
    }
    @media screen and (max-width: 460px) {
        width: 100%;
        .avatar {
            width: 270px;
            height: 270px;
        }
    }
}

.teamPopup {
    padding: 20px;
    background-color: var(--invertedPrimaryBg);
    color: var(--invertedPrimaryTextColor);
    width: 400px;
    border-radius: 5px;

    .popupCommandName {
        margin-top: 20px;
        margin-bottom: 20px;
        span {
            font-size: 18px;
            text-align: center;
            white-space: pre-line;
            font-weight: bold;
        }
    }

    .memberListSubTitle {

    }

    .memberList {
        margin-top: 10px;
        display: grid;
        grid-template-columns: 1fr;
        .member {
            justify-self: start;
            padding: 5px 0;
            white-space: pre-line;
            font-weight: bold;
            cursor: pointer;
            .cap {
                margin-left: 5px;
                font-weight: 400;
            }
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .closeBtnBox {
        margin-top: 20px;
    }
    //@media screen and (max-width: 460px) {
    //    width: 100%;
    //    .avatar {
    //        width: 270px;
    //        height: 270px;
    //    }
    //}
}

.adminCommentPopup {
    padding: 20px;
    width: 400px;
    border-radius: 5px;
    background-color: var(--invertedPrimaryBg);
    color: var(--invertedPrimaryTextColor);
    @media screen and (max-width: 460px) {
        width: 100%;
    }
}
