.InputTextArea {
  .title {
    color: var(--thirdPrimaryBtn);
  }
  .textarea {
    margin-top: 10px;
    min-height: 150px;
    background-color: var(--invertedPrimaryBg);
    padding: 10px;
    resize: none;

    &:focus::placeholder {
      color: transparent;
    }
    &:disabled {
      background-color: var(--thirdPrimaryBtn);
    }
    &.maxWidth {
      width: 100%;
    }
    &.white {
      background-color: white;
    }
    &.placeholderUppercase::placeholder {
      text-transform: uppercase;
    }
  }
}

