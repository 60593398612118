@keyframes topShow {
  0% {
    right: -100vw;
  }
  100% {
    right: 0;
  }
}
.warning {
  position: relative;

  margin-right: 15px;
  height: 30px;
  width: 30px;
  @media screen and (max-width: 767px) {
    //margin-right: 0;
  }
  svg {
    width: 100%;
    height: 100%;

    stroke: var(--secondColor);

    cursor: pointer;
  }
  .popupBox {
    position: absolute;
    z-index: 1;
    width: 350px;
    top: 45px;
    right: 0;
    transition: top .5s linear 0s;
    &.showPopup {
      top: 70px;
      @media (max-width: 767px) {
        top: 60px;
        right: -50px;
      }
      @media (max-width: 450px) {
        position: fixed;
        bottom: 0;
        right: 0;
        top: 60px;
        z-index: -10;
        width: calc(100vw);
        overflow-y: auto;
        //height: calc(100vh - 70px);
        //max-height: calc(100vh - 70px);
        //min-height: 100vh;
        animation: topShow .3s linear 0s;
        background-color: var(--primaryBg);
        padding-bottom: 60px;
      }
    }
  }
}
