.CategoryItem {

  margin-top: 20px;

  border: solid 1px var(--primaryBtn);
  border-radius: 5px;
  overflow: hidden;

  .main {
    //height: 100px;
    margin: 15px;
    .mainTop {
      display: grid;
      grid-template-columns: 1fr auto;
      .name {
        margin-top: 5px;
        font-size: 24px;
      }
      .changeBtn {
        width: 220px;
      }
    }
    .description {
      margin-top: 15px;
      line-height: 1.4;
      max-height: 60px;
      overflow: hidden;
      white-space: pre-wrap;
    }
  }

  .taskList {

    .listHeader {
      background-color: var(--primaryOpacityBtn);
      padding: 0 15px;
      height: 40px;
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      text-transform: uppercase;
      cursor: pointer;
      font-weight: bold;
      color: var(--grayColor);;
      svg {
        fill: var(--grayColor);
        width: 20px;
        height: 20px;

      }
      &:hover {
        background-color: var(--primaryBtn);
        color: var(--thirdPrimaryBtn);;
        svg {
          fill: var(--thirdPrimaryBtn);
        }
      }
      &.active {
        background-color: var(--primaryBtn);
        color: var(--thirdPrimaryBtn);
        svg {
          fill: var(--thirdPrimaryBtn);
          transform: rotate(180deg);
        }
      }
    }
    .list {
      background-color: var(--primaryOpacityBtn);
      .loadingList {
        min-height: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 20px;
        img {
          width: 40px;
        }
      }
      .emptyList {
        min-height: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: var(--grayColor);
      }
      .addBtnBox {
        width: 300px;
        margin: 0 auto;
        padding: 15px;
      }
    }
  }

  &:hover {
    //border: solid 1px var(--primaryBtn);
  }
  @media screen and (max-width: 767px){
    .main {
      .mainTop {
        grid-template-columns: 1fr;
        gap: 20px;
        .changeBtn {
          width: auto;
        }
      }
    }
  }
}

.TaskItem {
  border-bottom: 1px solid (var(--opacityBlackColor));

  .TaskItemHead {
    min-height: 40px;
    display: grid;
    grid-template-columns: 1fr auto;
    .name {
      display: flex;
      align-items: center;
      padding: 12px;
      span {
        margin-right: 10px;
        color: var(--grayColor);
      }
    }
    .changeBtnBox {
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        color: var(--grayColor);
        cursor: pointer;
        &:hover {
          color: var(--primaryTextColor);
        }
      }
    }
    @media screen and (max-width: 767px){
      grid-template-columns: 1fr;
      .name {
        order: 2;
      }
      .changeBtnBox {
        order: 1;
        width: auto;
        padding: 15px 5px 0;
      }
    }
  }
  .TaskItemMain {

    .description {
      padding: 12px;
      white-space: pre-wrap;
      line-height: 1.4;
      span {
        margin-right: 10px;
        color: var(--grayColor);
      }
    }
  }
  &:hover {
    background-color: (var(--primaryOpacityBtn));
  }

}
