.ChangePasswordPage {
  min-height: calc(100vh - 80px);
  .container {
    max-width: 1470px;
    max-width: 400px;
    margin: 0 auto;
    padding: 0 15px;
    .titlePage {
      margin-top: 20px;
      font-weight: 700;
      font-size: 34px;
      padding: 10px 0;
      text-transform: uppercase;
      //text-align: center;
      @media screen and (max-width: 720px ){
        font-size: 28px;
      }
    }
    .error {
      font-size: 18px;
      color: var(--secondColor);
    }
    .main {
      margin: 0 auto;
      padding: 20px 0;
      .form {
        max-width: 400px;
        width: 100%;
        //display: flex;
        //flex-direction: column;
        display: grid;
        grid-template-columns: 1fr;
        align-content: start;
        row-gap: 20px;
        input {
          margin-top: 5px;
        }
        .sendBtnBox {
          margin-top: 10px;
          width: 100%;
        }
      }
    }
  }
}
