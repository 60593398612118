@keyframes topShow {
  0% {
    right: -100vw;
  }
  100% {
    right: 0;
  }
}
.EmptyProfileButton {
  margin-right: 5px;
}
.ProfileButton {
  position: relative;
  .btnMain {
    max-width: 200px;
    .btn {
      cursor: pointer;
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      column-gap: 10px;
      .nameBox {
        top: 2px;
        width: 100%;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        //text-transform: uppercase;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        span {
          position: relative;
          display: block;
          &:after {
            content: '';
            display: block;
            position: relative;
            left: -100%;
            height: 2px;
            width: 100%;
            background-color: white;
          }
        }
        .noName {
          color: var(--grayColor);
          &:after {
            background-color: var(--grayColor);
          }
        }

        //@media screen and (max-width: 1450px) {
        //  display: none;
        //}
        @media screen and (max-width: 1350px) {
          display: block;
        }
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
      .iconBox {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(241, 241, 241, 0.12);
        border-radius: 50%;
        svg {
          width: 18px;
          height: 18px;
        }
        &.open {
          @media screen and (max-width: 450px) {
            border: 1px solid transparent;
            border-radius: 0;
            position: relative;
            svg {
              display: none;
            }
            &:before, &:after {
              content: '';
              display: block;
              position: absolute;
              height: 2px;
              width: 100%;
              background-color: white;
              transform: rotate(45deg);
            }
            &:after {
              transform: rotate(-45deg);
            }
          }
        }
      }
      &:hover {
        span:after {
          transition: left .2s linear 0s;
          left: 0;
        }
        .iconBox {
          background-color: rgba(241, 241, 241, 0.12);
          svg {
            fill: white;
          }
        }
      }

    }
    &.showPopup {
      .btn {
        span:after {
          //transition: left .2s linear 0s;
          //left: 0;
        }
        .iconBox {
          background-color: rgba(241, 241, 241, 0.12);
          svg {
            fill: white;
          }
        }
      }
    }
    @media screen and (max-width: 1350px) {
      //width: 200px;
      display: flex;
      justify-content: flex-end;
    }
    @media screen and (max-width: 767px) {
      width: 60px;
      .btn {
        column-gap: 0;
        padding: 8px;
      }
    }
  }
  .popupBox {
    position: absolute;
    z-index: 1;
    width: 400px;
    top: 45px;
    right: 0;
    transition: top .5s linear 0s;
    @media screen and (max-width: 767px) {
      right: 10px;
    }
    &.showPopup {
      top: 70px;
      @media (max-width: 450px) {
        position: fixed;
        bottom: 0;
        right: 0;
        top: 70px;
        z-index: -10;
        width: calc(100vw);
        overflow-y: auto;
        //height: calc(100vh - 70px);
        //max-height: calc(100vh - 70px);
        //min-height: 100vh;
        animation: topShow .3s linear 0s;
        background-color: var(--primaryBg);
        //padding-bottom: 60px;
      }
    }
  }
  @media screen and (max-width: 450px) {
    .popupBox {
      //width: calc(100vw);
    }
  }
}
