.TournamentPartners {
    margin-top: 20px;
    padding: 0 15px;

    .title {
        font-weight: 700;
        font-size: 34px;

    }

    .list {
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .partnerItem {
            height: 200px;
            width: 200px;
            min-height: 150px;
            min-width: 150px;
            //
            &:hover {
                box-shadow: 1px 1px 5px 1px  var(--grayColor);
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @media screen and (max-width: 1500px) {
                height: calc(14vw - 10px);
                width: calc(14vw - 10px);
            }
        }
    }

    @media screen and (max-width: 540px) {
        .title {
            font-size: 24px;
        }
    }
    @media screen and (max-width: 360px) {
        .title {
            //text-align: center;
        }
        .list {
            //gap: 10px;
            justify-content: center;

            .partnerItem {
                height: calc(80vw - 40px);
                width: calc(80vw - 40px);
            }
        }
    }
}
