.ConfirmResultPopup {
  background-color: var(--invertedPrimaryBg);
  color: var(--invertedPrimaryTextColor);
  padding: 20px 30px;
  width: 600px;
  .head {
    font-weight: bold;
    font-size: 24px;
  }
  .name {
    margin-top: 20px;
    height: 30px;
    color: var(--grayColor);
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      font-weight: bold;
      font-size: 18px;
      color: var(--blackColor);
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .description {
    margin-top: 10px;
    min-height: 30px;
    color: var(--grayColor);
    font-size: 16px;
    word-wrap: anywhere;
    white-space: pre-wrap;
    span {

      color: var(--blackColor);
    }
  }
  .videoBlock {
    margin-top: 20px;
    background-color: black;
  }
  .type {
    margin-top: 20px;
    font-weight: bold;
  }
  .value {
    margin-top: 10px;
    div {
      color: black;
    }
    input {
      background-color: white;
      border: 1px solid rgba(18, 18, 18, 0.12);
    }
    .time {
      display: flex;
      column-gap: 20px;
      input {
        width: 100px;
      }
    }
    .amount {
      input {
        width: 152px;
      }
    }
    .weight {
      display: flex;
      column-gap: 20px;
      input {
        width: 100px;
      }
    }
    &.disable {
      pointer-events: none;
      input {
        background-color: #e3e3e3;
      }
    }
  }
  .comment {
    margin-top: 20px;
    margin-bottom: 20px;
    .subTitle {

    }
    textarea {
      margin-top: 8px;
      width: 100%;
      resize: none;
      height: 150px;
      overflow-y: scroll;
      padding: 10px;
      border: 1px solid rgba(18, 18, 18, 0.12);
    }
    &.disable {
      pointer-events: none;
      textarea {
        background-color: #e3e3e3;
      }
    }
  }
  .btnBox {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
  }
  .editBtnBox {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 20px;
  }
  @media screen and (max-width: 660px) {
    width: calc(100vw - 60px);
    .videoBlock {
      iframe {
        width: calc(100vw - 120px);
        height: calc(100vw / 1.65 - 100px);
      }
    }
  }
  @media screen and (max-width: 420px) {
    padding: 20px 10px;
    width: calc(100vw - 20px);
    .videoBlock {
      iframe {
        width: calc(100vw - 40px);
        height: calc(100vw / 1.65 - 25px);
      }
    }
    .btnBox {
      grid-template-columns: 1fr;
      gap: 10px;
    }
  }
}
