.AdminResultItem {
    margin-top: 10px;
    height: 40px;
    display: grid;
    grid-template-columns: 1fr 100px auto auto auto;
    column-gap: 10px;

    &:nth-child(even) {
        .userName {
            background-color: rgba(42, 41, 54, 0.63);
        }

        .youtubeLink {
            background-color: rgba(42, 41, 54, 0.63);
        }

        .taskName {
            background-color: rgba(42, 41, 54, 0.63);
        }

        .status {
            background-color: rgba(42, 41, 54, 0.63);
        }

        .changeBtn {
            background-color: rgba(42, 41, 54, 0.63);
        }
    }

    .userName, .youtubeLink, .status, .change, .taskName {
        min-height: 40px;
        white-space: nowrap;
        overflow: hidden;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0 10px;
        color: var(--thirdPrimaryBtn);

        &:hover {
            background-color: var(--hoverBtn);
            color: var(--primaryTextColor);
        }
    }

    .userName {
        background-color: rgba(42, 41, 54, 0.4);

        span {
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .taskName {
        background-color: rgba(42, 41, 54, 0.4);

        span {
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .youtubeLink {
        background-color: rgba(42, 41, 54, 0.4);
        width: 260px;
        text-decoration: none;
    }

    .status {
        pointer-events: none;
        justify-content: center;
        background-color: rgba(42, 41, 54, 0.4);
        width: 200px;
        display: grid;
        text-align: center;
        grid-template-columns: 1fr 20px;
        gap: 10px;

        .icon {
            min-height: auto;
            fill: var(--grayColor);
            padding: 0;
            width: 100%;

            &.await {
                fill: #595959;
            }

            &.access {
                fill: #017c01;
            }

            &.change {
                fill: var(--thirdColor);
            }

            &.reject {
                fill: #7c0101;
            }
        }
    }

    .changeBtn {
        justify-content: center;
        background-color: rgba(42, 41, 54, 0.4);
        width: 40px;
        display: flex;
        align-items: center;
        cursor: pointer;

        svg {
            fill: var(--grayColor);
        }

        &:hover {
            svg {
                fill: var(--primaryTextColor);
            }
        }
    }

    @media screen and (max-width: 991px) {
        grid-template-columns: 1fr auto auto auto auto;
        min-height: 40px;
        height: auto;
        column-gap: 0px;
        .userName, .youtubeLink, .status, .change, .taskName {

        }
        .userName {
            grid-column: span 5;
        }
        .taskName {
            grid-column: span 3;
            width: 100%;
        }
        .status {
            width: 100%;
        }
    }
    @media screen and (max-width: 520px) {
        grid-template-columns: 1fr auto;
        min-height: 40px;
        height: auto;
        column-gap: 0px;
        .userName, .youtubeLink, .status, .change, .taskName {
            width: 100%;
        }
        .userName {
            grid-column: span 2;
        }
        .taskName {
            grid-column: span 2;
            width: 100%;
        }
        .status {
            width: 100%;

        }
    }
}
