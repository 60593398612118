.ManagerLayout {
  min-height: calc(100vh - 80px);
  .container {
    height: 100%;
    max-width: 1470px;
    margin: 0 auto;
    padding: 0 15px;
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 20px;
  }
}
