
.EditTaskForm {
  padding: 30px 0 0;
  min-height: calc(100vh - 80px);
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  .backBtnBox {
    width: 100%;
    margin: 10px auto 0;
    padding: 0 15px;
  }
  .container {
    position: relative;
    max-width: 1470px;
    padding: 0 15px 15px;
    margin: 10px auto 0;
    width: 100%;
    display: grid;
    grid-template-rows: auto 1fr;

    .head {
      margin-top: 15px;
      font-size: 34px;
      font-weight: bold;
      text-transform: uppercase;
      padding: 0 10px;
    }

    .main {
      width: 100%;
      margin-top: 20px;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr auto;

      .form {
        position: relative;
        z-index: 1;
        //max-width: 700px;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 20px;
        align-content: start;
      }
      .bigDescription {
        .title {
          margin-bottom: 14px;
          color: var(--thirdPrimaryBtn);
          &.dark {
            color: var(--litePrimaryBg);
          }
        }
        .editor {
          min-height: 400px;
          background-color: white;
        }
      }
      .btnBox {
        margin: 20px 0 10px;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 30px;
      }
    }
  }
  @media screen and (max-width: 767px){
    .container {
      .head {
        font-size: 28px;
        word-wrap: anywhere;
      }
    }
  }
}
.editBigDescription {
  padding: 20px;
  background-color: var(--invertedPrimaryBg);
  color: black;
  min-width: 800px;
  @media screen and (max-width: 991px){
    min-width: calc(100vw - 100px);
  }
  @media screen and (max-width: 420px){
    min-width: calc(100vw);
    min-height: calc(100vh);
    padding-top: 50px;
  }
}
