.CategoryItem {
  border-top: solid 1px rgba(126, 124, 161, 0.32);
  padding: 10px 0 20px;
  .name {
    font-weight: 700;
    font-size: 28px;
  }
  .description {
    margin-top: 10px;
    font-size: 18px;
    white-space: pre-wrap;
  }
  .price {
    margin-top: 10px;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 400;
  }
  .bayBtn {
    margin-top: 10px;
    max-width: 400px;
  }
}
