@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.EmailWarningPopup {
  width: 100%;
  background-color: var(--primaryBg);
  animation: show .1s linear 0s;
  box-shadow: 0px 0px 5px 0px var(--hoverBtn);
  border-radius: 8px;
  padding: 25px 25px;
  margin-bottom: 20px;
  height: calc(100%);
  @media screen and (max-width: 450px) {
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 0;
    padding: 10px 10px;
  }
  .title {
    font-weight: 600;
    font-size: 24px;
  }
  .subtitle {
    margin-top: 15px;

    font-weight: 500;
    font-size: 18px;
    &.success {
      color: var(--secondGreenColor);
    }
    &.error {
      color: var(--secondColor);
    }
  }
  .info {
    margin-top: 20px;

    font-size: 16px;
    font-weight: 400;
    .item {
      margin-top: 5px;
    }
    .buttonBlock {
      display: flex;
      justify-content: start;
      gap: 10px;
      margin-top: 15px;
      .button {
        font-weight: 500;
        font-size: 16px;
        color: var(--thirdPrimaryBtn);
        text-decoration: underline;

        background-color: transparent;

        transition: color .2s ease;
        &:hover {
          color: var(--primaryTextColor);
        }
      }
      .button:disabled {
        color: var(--opacityBlackColor);
      }
      .button:active {
        color: var(--hoveSecondColor);
      }
    }
  }
}
