.TournamentListPage {
  //padding: 40px 0;
  min-height: calc(100vh - 80px);
  .container {
    max-width: 1470px;
    padding: 0 15px;
    margin: 0 auto 0;
    .head {
      margin-top: 20px;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;

      .headTitle {
        padding: 10px 0;
        margin-right: 30px;
        font-weight: 700;
        font-size: 34px;
        text-transform: uppercase;
      }
      @media screen and (max-width: 991px){
        align-items: start;
        margin-top: 10px;
        .headTitle {
          margin-top: 5px;
          font-weight: 700;
          font-size: 24px;
          line-height: 100%;
        }
      }
      @media screen and (max-width: 540px){
        //padding: 0 10px;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
      }
    }
    .filter {
      margin-top: 20px;
      display: flex;
      justify-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 20px;
      //padding: 10px 10px;
      @media screen and (max-width: 991px){
        margin-top: 10px;
      }
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
      row-gap: 40px;
      padding: 30px 0;
      .loadingList {
        min-height: 450px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 50px;
        }
      }
      .emptyList {
        min-height: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: var(--grayColor);
      }
      @media screen and (max-width: 991px){
        padding-top: 20px;
      }
      @media screen and (max-width: 767px) {
        gap: 10px;
        row-gap: 30px;
      }
    }
    .btnBox {
      margin-top: 20px;
      margin-bottom: 40px;
      padding: 0 15px;
    }
  }
  @media screen and (max-width: 767px) {
    //padding: 50px 0;
    min-height: calc(100vh - 60px);
  }
  @media screen and (max-width: 540px) {
    //padding: 30px 0;
    .container {
      //padding: 0;
    }
  }
}
