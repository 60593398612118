.RadioButton {
  .title {
    color: var(--thirdPrimaryBtn);
  }
  .list {
    margin-top: 10px;
    .element {
      min-height: 40px;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 5px 0 5px 10px;
      span {
        flex: 0 0 16px;
        margin-right: 10px;
        display: inline-block;
        width: 16px;
        height: 16px;
        border: solid 1px white;
        border-radius: 3px;
        &.active {
          background-color: white;
        }
      }
      &:nth-child(odd) {
        background-color: var(--primaryBtn);
      }
      &:hover {
        background-color: var(--hoverBtn);
      }
    }
  }

  &.white {
    .title {
      color: var(--invertedPrimaryTextColor);
    }
    .list {
      .element {
        span {
          flex: 0 0 16px;
          margin-right: 10px;
          display: inline-block;
          width: 16px;
          height: 16px;
          border: solid 1px var(--grayColor);
          border-radius: 3px;
          &.active {
            background-color: var(--grayColor);
          }
        }
        &.active {
          background-color: rgba(128, 128, 128, 0.32);
          &:hover {
            background-color: rgba(108, 108, 108, 0.32);
          }
        }
        background-color: rgba(222, 222, 222, 0.32);
        &:hover {
          background-color: rgba(210, 210, 210, 0.32);
        }
      }
    }
  }
}
