.ProfilePage {
  min-height: calc(100vh - 80px);
  .container {
    max-width: 1470px;
    margin: 0 auto;
    padding: 0 15px;
    .titlePage {
      margin-top: 20px;
      font-weight: 700;
      font-size: 34px;
      padding: 10px 0;
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      .clue {
        margin-top: 10px;
        //margin-left: 10px;
        font-weight: 400;
        text-transform: none;
        font-size: 16px;
        color: var(--thirdPrimaryBtn);
      }
    }
    .error {
      font-size: 18px;
      color: var(--hoveSecondColor);
    }
    .main {
      margin: 20px auto 40px;
      //padding: 40px 0;
      display: grid;
      grid-template-columns: auto auto;
      justify-content: center;
      column-gap: 20px;
      .form {
        max-width: 700px;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        align-content: start;
        row-gap: 20px;

        .sendBtnBox {
          margin-top: 20px;
          width: 100%;
        }
      }
      .avatar {
        margin-top: 6px;
        width: 350px;
        height: 350px;
        min-width: 300px;
        min-height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: flex-end;
        padding: 23px 0 0 23px;
        .imgBox {
          max-width: 475px;
          max-height: 475px;
          width: 100%;
          height: 100%;

          .imgBoxWrapper{
            overflow: hidden;

            max-width: 475px;
            max-height: 475px;
            width: 100%;
            height: 100%;

            background-color: gray;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .changeFile {
          width: 100%;
          cursor: pointer;
          .fileName {
            margin-top: 20px;
            height: 40px;
            background-color: var(--primaryBtn);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            &:hover {
              background-color: var(--hoverBtn);
            }
          }
          .inputLogo {
            width: 0;
            height: 0;
          }
          &.disable {
            pointer-events: none;
          }
        }
        @media screen and (max-width: 1500px ){
          width: calc(40vw - 40px);
          height: calc(40vw - 40px);
        }

        @media screen and (max-width: 500px ){
          width: calc(100vw - 40px);
          height: calc(100vw - 40px);
        }
      }
    }
  }
  .inputWrapper {

    .title {
      margin-bottom: 5px;

      color: var(--thirdPrimaryBtn);
    }

    .inputs {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      @media screen and (max-width: 500px ){
        flex-direction: column;
      }
    }
  }
  @media screen and (max-width: 540px ){
    .container {
      .titlePage {
        font-size: 24px;
      }
      .main {
        margin: 0 auto 20px;
      }
    }
  }
}

@media screen and (max-width: 1024px ){
  .ProfilePage {
    .container {
      .main {
        grid-template-columns: auto;
        gap: 30px;

        .form {
          order: 2;
        }

        .avatar {
          order: 1;
          padding: 0;
          margin-bottom: 50px;
        }
      }
    }
  }
}

/*@media screen and (max-width: 768px ){
  .ProfilePage {
    .inputWrapper {
      .inputs {
        flex-direction: column;
      }
    }
  }
}*/
