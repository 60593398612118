.Header {
  background: var(--litePrimaryBg);
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 80px;
  top: 0;
  left: 0;
  right: 0;
  .container {
    height: 80px;
    max-width: 1470px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    .logo {
      cursor: pointer;
      svg {
        width: 78px;
        height: 33px;
      }
      span {
        display: block;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 13px;
        text-transform: uppercase;
        color: #CA443B;
      }
    }
    .profile {
      display: flex;
      align-items: center;
    }
  }
  @media screen and (max-width: 767px){
    height: 60px;
    .container {
      height: 60px;
      padding: 0 0 0 10px;
      .logo {
        svg {
          width: 59px;
          height: 25px;
        }
        span {
          font-size: 8px;
          line-height: 10px;
        }
      }
    }
  }
}
