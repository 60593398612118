.RemindPasswordPage {
  min-height: calc(100vh - 80px);
  .container {
    max-width: 1470px;
    height: 100%;
    margin: 0 auto;
    padding: 0 15px;
    .loading {
      min-height: 100px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 20px;
      img {
        width: 40px;
      }
    }
    .errorMessage {
      padding: 10px 0;

      color: var(--secondColor);
      font-weight: 700;
      font-size: 18px;
      text-transform: uppercase;
    }
    .title {
      margin-top: 20px;
      font-weight: 700;
      font-size: 34px;
      padding: 10px 0;
      text-transform: uppercase;
      @media screen and (max-width: 720px ){
        font-size: 28px;
      }
    }
    .main {
      padding: 40px 0;
      display: grid;
      grid-template-columns: auto;
      max-width: 600px;
      min-width: 300px;
      width: 100%;
      .form {
        max-width: 700px;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        align-content: start;
        row-gap: 30px;
        .buttonBlock {
          width: 100%;
        }
      }
    }
  }
}
