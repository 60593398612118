
.SelectCategory {
  min-height: calc(100vh - 80px);
  .container {
    max-width: 1470px;
    margin: 0 auto;
    padding: 0 15px;
    .main {
      padding: 20px 0;
      .titlePage {
        margin-top: 10px;
        font-weight: 700;
        font-size: 34px;
        padding: 10px 0;
        text-transform: uppercase;
      }
      .list {
        //margin-top: 10px;
        display: grid;
        grid-template-columns: 1fr;
        //row-gap: 20px;
      }
      .loadingList {
        min-height: 450px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 20px;
        img {
          width: 50px;
        }
      }
    }
  }
  @media screen and (max-width: 991px){
    .container {
      .main {
        padding: 20px 0;
      }
    }
  }
  @media screen and (max-width: 540px){
    .container {
      .main {
        .titlePage {
          font-size: 24px;
        }
      }
    }
  }
}
