.dots {
  margin-top: 15px;
  width: 100%;
  display: flex !important;
  justify-content: center;
  column-gap: 10px;
  z-index: 10;
  li {
    display: block;
    width: 15px;
    height: 15px;
    background-color: var(--primaryOpacityBtn);
    border-radius: 50%;
    cursor: pointer;
    button {
      width: 100%;
      height: 100%;
      overflow: hidden;
      opacity: 0;
    }
    &:hover {
      background-color: var(--hoverBtn);
    }
  }
  .slick-active {
    background-color: var(--thirdPrimaryBtn);
    &:hover {
      background-color: var(--thirdPrimaryBtn);
    }
  }
}
