.TaskItem {
  position: relative;
  margin-top: 20px;
  background: var(--primaryOpacityBtn);
  border-radius: 8px;
  animation: visible .2s linear .1s both;
  padding: 0 15px;
  background-color: var(--primaryBg);
  border: none;
  min-height: calc(100vh - 210px);
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  .backBtn {
    max-width: 300px;
  }
  .title {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr auto;
    .name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: 700;
      font-size: 28px;
    }
  }
  .isOpenBtn {
    margin-top: 10px;
    span {
      display: inline-block;
      margin-right: 10px;
      font-size: 18px;
      cursor: pointer;
      //text-transform: uppercase;
      //text-decoration: underline;
      border-bottom: solid 1px var(--grayColor);
      color: var(--grayColor);
      &:hover {
        color: var(--primaryTextColor);
        border-bottom: solid 1px var(--primaryTextColor);
      }
    }
    b {
      margin-left: 10px;
    }
  }
  .description {
    margin-top: 20px;
    white-space: pre-wrap;
    line-height: 1.4;
  }
  .bigDescription {
    margin-top: 20px;
  }
  .moreBtn {
    margin-top: 10px;
  }
  .main {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 20px;
    .resultBox {
      max-width: 500px;
      .link {
      }
      .result {
        margin-top: 20px;
        .time {
          display: flex;
          column-gap: 20px;
          input {
            width: 100px;
          }
        }
        .amount {
          input {
            width: 152px;
          }
        }
        .weight {
          display: flex;
          column-gap: 20px;
          input {
            width: 100px;
          }
        }
      }
      &.disable {
        pointer-events: none;
        input {
          background-color: #adadad;
        }
      }
    }
    .btnBox {
      align-self: end;
      min-width: 300px;
      max-width: 400px;
    }
  }
  &.await {
    border: 1px solid gray;
    .title {
      .status {
        color: gray;
      }
    }
  }
  &.access {
    border: 1px solid green;
    .title {
      .status {
        color: green;
      }
    }
  }
  &.reject {
    border: 1px solid red;
    .title {
      .status {
        color: red;
      }
    }
  }

  @keyframes visible {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
