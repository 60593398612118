.CategoryList {
  padding: 30px 0;
  min-height: calc(100vh - 80px);
  .container {
    max-width: 1470px;
    padding: 0 15px;
    margin: 10px auto 0;

    .head {
      margin-top: 20px;
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      padding: 0 10px;

      .headTitle {
        margin-right: 30px;
        font-weight: 700;
        font-size: 34px;
        text-transform: uppercase;
      }

      @media screen and (max-width: 1200px) {
        .headTitle {
          font-weight: 700;
          font-size: 34px;
          text-transform: uppercase;
        }
      }
      @media screen and (max-width: 991px) {
        align-items: start;
        .headTitle {
          margin-top: 5px;
          font-weight: 700;
          font-size: 24px;
          line-height: 100%;
        }
      }
      @media screen and (max-width: 540px) {
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
      }
    }
    .list {
      //margin-top: 10px;
      display: grid;
      grid-template-columns: 1fr;
      //row-gap: 20px;
    }
    .loadingList {
      min-height: 450px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 20px;
      img {
        width: 50px;
      }
    }
    .emptyList {
      min-height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: var(--grayColor);
    }
  }
}
