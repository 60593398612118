.loadingList {
  min-height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
  img {
    width: 50px;
  }
  span {
    font-size: 16px;
  }
}
.TournamentPage {
  min-height: calc(100vh - 80px);
  .container {
    width: 100%;
    max-width: 1470px;
    margin: 0 auto;
    padding-bottom: 20px;
    //padding: 0 15px;
  }
}
