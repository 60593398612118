.Button {
  display: inline-flex;
  padding: 12px 24px;
  border-radius: 8px;
  background-color: var(--primaryBtn);
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: var(--primaryTextColor);
  white-space: pre-wrap;
  span {
    font-weight: 600;
    font-size: 18px;
  }
  &:hover {
    background-color: var(--hoverBtn);
  }
  &.active {
    background-color: var(--hoverBtn);
  }
  &.noRadius {
    border-radius: 0;
  }
  &.maxWidth {
    width: 100%;
  }
  &.red {
    background-color: var(--secondColor);
    &:hover {
      background-color: var(--hoveSecondColor);
    }
    &:disabled {
      background-color: var(--secondColor);
      color: white;
      opacity: .5;
    }
  }
  &.yellow {
    background-color: var(--thirdColor);
    &:hover {
      background-color: var(--hoverThirdColor);
    }
  }
  &.green {
    background-color: var(--secondHardGreenColor);
    &:hover {
      background-color: var(--secondGreenColor);
    }
  }
  &.grey {
    background-color: var(--primaryBtn);
    &:hover {
      background-color: var(--hoverBtn);
    }
  }
  &.dark {
    background-color: var(--litePrimaryBg);
    &:hover {
      background-color: var(--hoverBtn);
    }
    &:disabled {
      background-color: var(--litePrimaryBg);
      opacity: .5;
    }
  }
  &.uppercase {
    text-transform: uppercase;
  }
  &.mobilSmall {
    @media screen and (max-width: 767px) {
      padding: 10px 10px;
      span {
        font-weight: 600;
        font-size: 16px;
      }
    }
    @media screen and (max-width: 350px) {
      span {
        font-size: 14px;
      }
    }
  }
  &.isLoading {
    position: relative;
    pointer-events: none;
    opacity: .8;
    span {
      opacity: 0;
    }
    img {
      display: block;
      position: absolute;
      top: 7px;
      width: 32px;
      left: 50%;
      transform: translateX(-50%);
    }

  }
  &.maxHeight {
    height: 100%;
  }

  &:disabled {
    background-color: var(--primaryOpacityBtn);
    pointer-events: none;
    color: var(--grayColor);
  }
  &.disableLite:disabled {
    background-color: var(--opacityBlackColor);
    pointer-events: none;
    color: var(--grayColor);
  }
}
