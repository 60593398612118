.ManagerNavigate {

  width: 250px;
  padding: 30px 0;
  .navItem {
    position: sticky;
    z-index: 1;
    min-height: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: var(--primaryBg);
    border-bottom: 1px solid var(--primaryBtn);
    padding-left: 10px;
    &:last-child {
      border-bottom: 1px solid transparent;
    }
    &:hover {
      background-color: var(--primaryLightOpacityBtn);
    }
    &.active {
      background-color: var(--hoverBtn);
    }
  }
}
