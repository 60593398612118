.PartnerPage {
  padding: 20px 0 40px 25px;
  .head {
    font-size: 34px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .addBtnBox {
    margin-top: 20px;
  }
  .list {
    margin-top: 20px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    .loadingList {
      width: 100%;
      min-height: 450px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 50px;
      }
    }
    .emptyList {
      width: 100%;
      min-height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: var(--grayColor);
    }
    .partnerItem {
      display: flex;
      border: solid 1px var(--grayColor);
      .img {
        width: 160px;
        height: 160px;
        background-color: var(--grayColor);
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .main {
        width: 200px;
        height: 160px;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .name {
          max-height: 70px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -moz-box;
          -moz-box-orient: vertical;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          line-clamp: 4;
          box-orient: vertical;
        }
        .btnBox {

        }
      }
      @media screen and (max-width: 767px){
        flex-wrap: wrap;
        padding: 10px;
        .img {
          margin: 0 auto;
          width: 160px;
          height: 160px;
          background-color: var(--grayColor);
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1100px){
    padding: 20px 0 40px 10px;
    .head {
      font-size: 28px;
    }
  }
  @media screen and (max-width: 767px){
    padding: 20px 0 40px 5px;
  }
}

.EditPartner {
  padding: 20px;
  background-color: var(--invertedPrimaryBg);
  width: 400px;
  border-radius: 5px;
  .img {
    margin: 10px auto 0;
    width: 200px;
    height: 200px;
    background-color: var(--grayColor);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .changeFile {
    width: 100%;
    cursor: pointer;
    .fileName {
      margin-top: 20px;
      height: 40px;
      background-color: var(--primaryBtn);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      &:hover {
        background-color: var(--hoverBtn);
      }
    }
    .inputLogo {
      width: 0;
      height: 0;
    }
    &.disable {
      pointer-events: none;
    }
  }
  .inputName {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .title {
      color: black;
    }
    input {
      margin-top: 0;
    }
  }
  .btnBox {
    margin-top: 20px;
    display: flex;
    gap: 20px;
  }
  @media screen and (max-width: 460px){
    width: auto;
  }
}
