.AppLayout {
  position: relative;
  padding-top: 80px;
  background-color: var(--primaryBg);
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100vh;
  @media screen and (max-width: 767px) {
    padding-top: 60px;
  }
}
