.ConfirmEmailPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  font-size: 24px;
  font-weight: 600;
  .message {
    margin-bottom: 15px;

    text-transform: uppercase;
  }
  .homeButton {
    padding: 10px 20px;

    font-size: 24px;
    font-weight: 600;
    color: var(--primaryTextColor);
    text-transform: uppercase;

    background-color: var(--primaryBtn);
    border-radius: .2em;

    &:hover {
      background-color: var(--hoverBtn);
    }
  }
}
