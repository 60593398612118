.TournamentHead {
  margin-top: 20px;
  overflow: hidden;
  display: grid;
  //display: none;
  grid-template-columns: auto 1fr;
  column-gap: 30px;
  padding: 20px 15px;
  .logo {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .main {
    padding: 20px 0;
    display: grid;
    grid-template-rows: 1fr auto;
    .info {
      overflow: hidden;
      .name {
        font-weight: 700;
        font-size: 34px;
        text-transform: uppercase;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .smallInfo {
        display: flex;
        gap: 20px;
        min-height: 60px;
        align-items: center;
        .date {
          font-weight: 600;
          font-size: 24px;
        }
        .isOnline {
          span.true {
            color: #00FFD1;
          }
          span.false {
            color: #ff0000;
          }
        }
      }
    }
    .btnBox {
      gap: 20px;
      display: grid;
      grid-template-columns: 1fr;
      .btnRow {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
        &.oneItem {
          grid-template-columns: 1fr;
        }
      }
      .myCategory {
        .subTitle {
          color: var(--thirdPrimaryBtn);
        }
        .categoryName {
          margin-left: 5px;
          &:after {
            content: ',';
          }
          &:last-of-type:after {
            content: '.';
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    padding: 0 15px;
    .logo {
      align-self: center;
    }
    .main {
      padding: 0;
      .btnBox {
        .btnRow {
          grid-template-columns: 1fr;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    .logo {
      justify-self: center;
    }
    .main {
      margin-top: 20px;
      .btnBox {
        max-width: 500px;
        margin: 0 auto;
        width: 100%;
        .btnRow {

        }
      }
    }
  }
  @media screen and (max-width: 540px) {
    grid-template-columns: 1fr;
    .logo {
      width: calc(100vw - 60px);
      height: calc(100vw - 60px);
      max-width: 300px;
      max-height: 300px;
    }
    .main {
      .info {
        .name {
          font-size: 24px;
        }
        .smallInfo {
          padding: 20px 0;
          flex-wrap: wrap;
          .date {
            font-size: 18px;
          }
        }
      }
    }
  }
}
